import { UploadContext } from '@contexts/upload-context'
import globalStyles from '@global/global.css'
import uploaderMachine from '@machines/uploader-machine'
import { Quicksand } from '@next/font/google'
import '@vidstack/player/hydrate.js'
import { useInterpret } from '@xstate/react'
import * as Fathom from 'fathom-client'
import NextAdapterPages from 'next-query-params/pages'
import { AppProps } from 'next/app'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import { useRouter } from 'next/router'
import 'normalize.css'
import { useEffect } from 'react'
import { QueryParamProvider } from 'use-query-params'

// If loading a variable font, you don't need to specify the font weight
const quickSand = Quicksand({
    subsets: ['latin']
})

const Layout = dynamic(() => import('@components/layout/Layout'), {
    ssr: false
})

const App = ({ Component, pageProps }: AppProps) => {
    const router = useRouter()

    const uploadService = useInterpret(uploaderMachine)

    useEffect(() => {
        Fathom.load(process.env.FATHOM_SITE_ID || '', {
            includedDomains: [
                'staging-vault.statemints.com',
                'vault.statemints.com'
            ]
        })

        function onRouteChangeComplete() {
            Fathom.trackPageview()
        }
        // Record a pageview when route changes
        router.events.on('routeChangeComplete', onRouteChangeComplete)

        // Unassign event listener
        return () => {
            router.events.off('routeChangeComplete', onRouteChangeComplete)
        }
    }, [router.events])

    globalStyles()
    return (
        <>
            <style jsx global>{`
                html {
                    font-family: ${quickSand.style.fontFamily};
                }
            `}</style>
            <Head>
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
                />
            </Head>

            <QueryParamProvider adapter={NextAdapterPages}>
                <UploadContext.Provider value={{ uploadService }}>
                    <Layout>
                        <Component {...pageProps} />
                    </Layout>
                </UploadContext.Provider>
            </QueryParamProvider>
        </>
    )
}

export default App
