import { PaymentDetails } from '@components/UploadSummary'
import { ActorRefFrom, assign, createMachine, StateFrom } from 'xstate'
import { TrimTimeInterface } from './trimmer-machine'

export interface UploadInfo {
    fileUrl: string
    type: 'video' | 'audio'
    displayName: string
    description: string
    duration: number
    file?: File
    recorded_at: string | null
}

export interface CompleteUploadIntent {
    filename: string
    sessionId?: string
}

export interface FinalizeSession {
    display_name: string
    description?: string
    time_spans: [number, number][]
}

interface UploaderContext {
    upload?: UploadInfo
    finalizeSession?: FinalizeSession
    completedUploadIntent?: CompleteUploadIntent
    summary?: PaymentDetails
    initialTimeSpan?: TrimTimeInterface[]
    isPrivate: boolean
    savingAsDraft: boolean
}

export type UploaderEvents =
    | {
          type: 'TOGGLE_TRIM'
          upload: UploadInfo
          isPrivate: boolean
      }
    | { type: 'UPLOAD'; upload: UploadInfo; isPrivate: boolean }
    | {
          type: 'FINISHED_TRIM_TO_UPLOAD'
          finalizeSession: FinalizeSession
          savingAsDraft?: boolean
      }
    | { type: 'FINISHED_TRIM_TO_SUMMARY' }
    | { type: 'SET_UPLOAD_INTENT'; completedUploadIntent: CompleteUploadIntent }
    | {
          type: 'TARGET_SUMMARY'
          summary: PaymentDetails
          completedUploadIntent: CompleteUploadIntent
      }
    | { type: 'UPDATE_SUMMARY'; summary: PaymentDetails }
    | {
          type: 'RESTORE_DRAFT'
          upload: UploadInfo
          initialTimeSpan: TrimTimeInterface[]
          completedUploadIntent: CompleteUploadIntent
      }
    | {
          type: 'FINISHED_UPLOAD'
      }
    | { type: 'RESET' }

const uploaderMachine = createMachine<UploaderContext, UploaderEvents>(
    {
        predictableActionArguments: true,
        id: 'uploader',
        context: {
            upload: undefined,
            finalizeSession: undefined,
            completedUploadIntent: undefined,
            summary: undefined,
            savingAsDraft: false,
            isPrivate: false
        },
        initial: 'idle',
        states: {
            idle: {
                on: {
                    TOGGLE_TRIM: {
                        target: 'trim',
                        actions: 'toggleTrim'
                    },
                    RESTORE_DRAFT: {
                        target: 'trim',
                        actions: 'restoreDraft'
                    },
                    UPLOAD: { target: 'upload', actions: 'targetUpload' },
                    TARGET_SUMMARY: {
                        target: 'summary',
                        actions: 'targetSummary'
                    },
                    RESET: {
                        target: 'idle',
                        actions: 'resetContext'
                    }
                }
            },
            trim: {
                on: {
                    FINISHED_TRIM_TO_UPLOAD: {
                        target: 'upload',
                        actions: 'updateFinalizeSession'
                    },
                    FINISHED_TRIM_TO_SUMMARY: {
                        target: 'summary'
                    },
                    SET_UPLOAD_INTENT: {
                        actions: 'setCompleteUploadIntent'
                    },
                    RESET: {
                        target: 'idle',
                        actions: 'resetContext'
                    }
                }
            },
            upload: {
                on: {
                    FINISHED_UPLOAD: {
                        target: 'summary'
                    },
                    SET_UPLOAD_INTENT: {
                        actions: 'setCompleteUploadIntent'
                    },
                    RESET: {
                        target: 'idle',
                        actions: 'resetContext'
                    }
                }
            },
            summary: {
                on: {
                    UPDATE_SUMMARY: {
                        actions: 'updateSummary'
                    },
                    RESET: {
                        target: 'idle',
                        actions: 'resetContext'
                    }
                }
            },
            done: {
                type: 'final',
                on: {
                    RESET: {
                        target: 'idle',
                        actions: 'resetContext'
                    }
                }
            }
        }
    },
    {
        actions: {
            toggleTrim: assign<UploaderContext, UploaderEvents>({
                upload: (ctx, event) => {
                    if (event.type === 'TOGGLE_TRIM') {
                        return event.upload
                    }

                    return ctx.upload
                },
                isPrivate: (ctx, event) => {
                    if (event.type === 'TOGGLE_TRIM') {
                        return event.isPrivate
                    }

                    return ctx.isPrivate
                }
            }),
            targetUpload: assign<UploaderContext, UploaderEvents>({
                upload: (context, event) => {
                    if (event.type === 'UPLOAD') {
                        return event.upload
                    }

                    return context.upload
                },
                isPrivate: (ctx, event) => {
                    if (event.type === 'UPLOAD') {
                        return event.isPrivate
                    }

                    return ctx.isPrivate
                }
            }),
            setCompleteUploadIntent: assign<UploaderContext, UploaderEvents>({
                completedUploadIntent: (ctx, event) => {
                    if (event.type === 'SET_UPLOAD_INTENT') {
                        return event.completedUploadIntent
                    }

                    return ctx.completedUploadIntent
                }
            }),
            updateFinalizeSession: assign<UploaderContext, UploaderEvents>({
                finalizeSession: (ctx, event) => {
                    if (event.type === 'FINISHED_TRIM_TO_UPLOAD') {
                        return event.finalizeSession
                    }

                    return ctx.finalizeSession
                },
                savingAsDraft: (ctx, event) => {
                    if (
                        event.type === 'FINISHED_TRIM_TO_UPLOAD' &&
                        event.savingAsDraft
                    ) {
                        return event.savingAsDraft
                    }

                    return ctx.savingAsDraft
                }
            }),
            targetSummary: assign<UploaderContext, UploaderEvents>({
                completedUploadIntent: (ctx, event) => {
                    if (event.type === 'TARGET_SUMMARY') {
                        return event.completedUploadIntent
                    }

                    return ctx.completedUploadIntent
                },
                summary: (ctx, event) => {
                    if (event.type === 'TARGET_SUMMARY') {
                        return event.summary
                    }

                    return ctx.summary
                }
            }),
            restoreDraft: assign<UploaderContext, UploaderEvents>({
                completedUploadIntent: (ctx, event) => {
                    if (event.type === 'RESTORE_DRAFT') {
                        return event.completedUploadIntent
                    }

                    return ctx.completedUploadIntent
                },
                upload: (ctx, event) => {
                    if (event.type === 'RESTORE_DRAFT') {
                        return event.upload
                    }

                    return ctx.upload
                },
                initialTimeSpan: (ctx, event) => {
                    if (event.type === 'RESTORE_DRAFT') {
                        return event.initialTimeSpan
                    }

                    return ctx.initialTimeSpan
                }
            }),
            updateSummary: assign<UploaderContext, UploaderEvents>({
                summary: (ctx, event) => {
                    if (event.type === 'UPDATE_SUMMARY') {
                        return event.summary
                    }

                    return ctx.summary
                }
            }),
            resetContext: assign<UploaderContext, UploaderEvents>(() => {
                return {
                    upload: undefined,
                    finalizeSession: undefined,
                    savingAsDraft: false,
                    completedUploadIntent: undefined,
                    summary: undefined,
                    initialTimeSpan: undefined,
                    isPrivate: false
                }
            })
        }
    }
)

export type uploadMachineState = StateFrom<typeof uploaderMachine>
export type uploadService = ActorRefFrom<typeof uploaderMachine>

export const getUploaderState = (state: uploadMachineState) => state
export const getFinalizeSession = (state: uploadMachineState) =>
    state.context.finalizeSession
export const getIsPrivate = (state: uploadMachineState) =>
    state.context.isPrivate
export const getSavingAsDraft = (state: uploadMachineState) =>
    state.context.savingAsDraft
export const getUploadInfo = (state: uploadMachineState) => state.context.upload
export const getCompletedIntent = (state: uploadMachineState) =>
    state.context.completedUploadIntent
export const getSummary = (state: uploadMachineState) => state.context.summary
export const getInitialTimeSpan = (state: uploadMachineState) =>
    state.context.initialTimeSpan

export default uploaderMachine
